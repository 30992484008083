/* global axios */
import { decodeBase64 } from 'dashboard/store/utils/api';
import Cookies from 'js-cookie';
import endPoints from './endPoints';
import {
  clearCookiesOnLogout,
  deleteIndexedDBOnLogout,
} from '../store/utils/api';

export default {
  validityCheck() {
    const urlData = endPoints('validityCheck');
    return axios.chatwoot.get(urlData.url);
  },
  validityCheckUblux(refreshToken) {
    return axios.ublux.get(
      `/v1/Public/Authorization/RefreshToken/${refreshToken}`
    );
  },
  logout() {
    const urlData = endPoints('logout');
    return new Promise((resolve, reject) => {
      axios.chatwoot
        .delete(urlData.url)
        .then(response => {
          const ubluxSdk = window.UbluxSDK.getInstance();
          if (ubluxSdk) {
            ubluxSdk.logout();
          }
          deleteIndexedDBOnLogout();
          clearCookiesOnLogout();
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  hasAuthCookie() {
    return !!Cookies.get('cw_d_session_info');
  },
  hasAuthCookieUblux() {
    return !!Cookies.get('ublux_session_info');
  },
  getAuthDataWoot() {
    if (this.hasAuthCookie()) {
      const savedAuthInfo = Cookies.get('cw_d_session_info');
      return JSON.parse(savedAuthInfo || '{}');
    }
    return false;
  },
  getAuthDataUblux() {
    if (this.hasAuthCookieUblux()) {
      const savedAuthInfo = Cookies.get('ublux_session_info');
      return JSON.parse(savedAuthInfo || '{}');
    }
    return false;
  },
  getAuthSessionExpire() {
    return Cookies.get('cw_d_session_expire');
  },
  setAuthDataUblux(data) {
    const user = decodeBase64(data.accessToken);
    Cookies.set(
      'ublux_session_info',
      JSON.stringify({
        ...data,
        userId: user.sub,
        expires: user.exp,
      }),
      {
        expires: Number(this.getAuthSessionExpire()),
      }
    );
  },
  profileUpdate({
    password,
    password_confirmation,
    displayName,
    avatar,
    ...profileAttributes
  }) {
    const formData = new FormData();
    Object.keys(profileAttributes).forEach(key => {
      const hasValue = profileAttributes[key] === undefined;
      if (!hasValue) {
        formData.append(`profile[${key}]`, profileAttributes[key]);
      }
    });
    formData.append('profile[display_name]', displayName || '');
    if (password && password_confirmation) {
      formData.append('profile[password]', password);
      formData.append('profile[password_confirmation]', password_confirmation);
    }
    if (avatar) {
      formData.append('profile[avatar]', avatar);
    }
    return axios.chatwoot.put(endPoints('profileUpdate').url, formData);
  },

  updateUISettings({ uiSettings }) {
    return axios.chatwoot.put(endPoints('profileUpdate').url, {
      profile: { ui_settings: uiSettings },
    });
  },

  updateAvailability(availabilityData) {
    return axios.chatwoot.post(endPoints('availabilityUpdate').url, {
      profile: { ...availabilityData },
    });
  },

  updateAutoOffline(accountId, autoOffline = false) {
    return axios.chatwoot.post(endPoints('autoOffline').url, {
      profile: { account_id: accountId, auto_offline: autoOffline },
    });
  },

  deleteAvatar() {
    return axios.chatwoot.delete(endPoints('deleteAvatar').url);
  },

  resetPassword({ email }) {
    const urlData = endPoints('resetPassword');
    return axios.chatwoot.post(urlData.url, { email });
  },

  setActiveAccount({ accountId }) {
    const urlData = endPoints('setActiveAccount');
    return axios.chatwoot.put(urlData.url, {
      profile: {
        account_id: accountId,
      },
    });
  },
  resendConfirmation() {
    const urlData = endPoints('resendConfirmation');
    return axios.chatwoot.post(urlData.url);
  },
};
